import axios from 'axios'
import qs from 'qs'
import router from '@/router'

let requestApi = localStorage.getItem('connector')

const service = axios.create({
    baseURL: 'https://api.xmw1688.cn',
    // baseURL: requestApi,
    timeout: 60000,
})

service.defaults.transformRequest = data => qs.stringify(data);

service.interceptors.request.use(config => {
    if (localStorage.getItem('token')) {
        config.headers.Authorization = localStorage.getItem('token')
        config.headers.accessToken = localStorage.getItem('token')
    }

    return config

}, (error) => Promise.reject(error))

service.interceptors.response.use(response => {

    const res = response.data
    // 这里是接口处理的一个示范，可以根据自己的项目需求更改


    // 如果接口正常，直接返回数据
    return res
}, (error) => {



    // if (error.name == 'AxiosError') {
    //     window.prompt("服务器正在维修，请稍后", "确认");
    //     return
    // } else {

    // }
    return Promise.reject(error)
})

export default service
